export const LOCALES = [
  'de_DE',
  'en_CA',
  'en_GB',
  'en_IN',
  'en_IE',
  'en_US',
  'es_ES',
  'fr_CA',
  'fr_FR',
  'it_IT',
  'pt_PT',
  'en_MY',
  'no_NO',
  'fi_FI',
  'de_CH',
  'fr_CH',
  'it_CH',
  'fr_BE',
  'nl_BE',
  'cs_CZ',
  'nl_NL',
  'de_AT',
  'da_DK',
  'en_ZA',
  'pl_PL',
  'hr_HR',
  'hu_HU',
  'pt_BR',
  'sk_SK',
  'sr_RS',
  'sv_SE',
  'sl_SI',
  'bg_BG',
  'tr_TR',
  'lv_LV',
  'lt_LT',
  'ro_RO',
  'bs_BA',
  'uk_UA',
  'zh_CN',
  'ja_JP',
];

export const LOCALE_DEFAULT = 'en_GB';

export const FALLBACK_IMAGE =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/4gHYSUNDX1BST0ZJTEUAAQEAAAHIAAAAAAQwAABtbnRyUkdCIFhZWiAH4AABAAEAAAAAAABhY3NwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAQAA9tYAAQAAAADTLQAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAlkZXNjAAAA8AAAACRyWFlaAAABFAAAABRnWFlaAAABKAAAABRiWFlaAAABPAAAABR3dHB0AAABUAAAABRyVFJDAAABZAAAAChnVFJDAAABZAAAAChiVFJDAAABZAAAAChjcHJ0AAABjAAAADxtbHVjAAAAAAAAAAEAAAAMZW5VUwAAAAgAAAAcAHMAUgBHAEJYWVogAAAAAAAAb6IAADj1AAADkFhZWiAAAAAAAABimQAAt4UAABjaWFlaIAAAAAAAACSgAAAPhAAAts9YWVogAAAAAAAA9tYAAQAAAADTLXBhcmEAAAAAAAQAAAACZmYAAPKnAAANWQAAE9AAAApbAAAAAAAAAABtbHVjAAAAAAAAAAEAAAAMZW5VUwAAACAAAAAcAEcAbwBvAGcAbABlACAASQBuAGMALgAgADIAMAAxADb/2wBDAAMCAgICAgMCAgIDAwMDBAYEBAQEBAgGBgUGCQgKCgkICQkKDA8MCgsOCwkJDRENDg8QEBEQCgwSExIQEw8QEBD/2wBDAQMDAwQDBAgEBAgQCwkLEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBD/wAARCAAFAAoDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAYI/8QAIhAAAgEDAgcAAAAAAAAAAAAAAQIDAAQFBhESFCExM1Hh/8QAFQEBAQAAAAAAAAAAAAAAAAAABAX/xAAZEQEAAwEBAAAAAAAAAAAAAAABAAIRQSH/2gAMAwEAAhEDEQA/AN36O09h8jI+a5Z4ZrKWaFQsrMrqpIHED391QqXKgmO36jfxfaUqeeGnYy7tkeT/2Q==';
export const MEDIA_DOMAIN = 'media.graphassets.com';
export const RECIPE_DOMAIN = 'recipesblob.oetker';
export const MEDIA_PREFIX = '/assets/hygraph';
export const RECIPE_PREFIX = '/assets/recipes';
export const MOBILE_MEDIA_BP = '(max-width: 767px)';
export const DESKTOP_MEDIA_BP = '(min-width: 768px)';
export const RECIPE_CONVERSION_TIMING_AFTER_SEARCH = 30000; // 30 seconds
export const HITS_PER_PAGE = 3;
export const ANONYMOUS_TOKEN_NAME = 'anonymous_token';
